<template>
    <div>
        <h4>Upload a new demonstration</h4>

        <br/>

        <div
                v-if="message!=''"
                class="alert alert-info"
                v-bind:class="{'alert-danger': isError }"
                role="alert"
        >
            {{ message }}
        </div>

        <div v-if="currentFile" class="progress mb-2">
            <div
                class="progress-bar progress-bar-info progress-bar-striped"
                role="progressbar"
                :aria-valuenow="progress"
                aria-valuemin="0"
                aria-valuemax="100"
                :style="{ width: progress + '%' }"
            >{{ progress }}%
            </div>
        </div>

        <b-form-file
                ref="file"
                @input="selectFile"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
        ></b-form-file>

        <br/><br/>

        <b-btn variant="success" block :disabled="!selectedFile" @click="upload">Upload</b-btn>
    </div>
</template>

<script>
	import DemonstrationsApi from "@/assets/js/DemonstrationsApi";
	export default {
		name: "UploadDemonstration",
		data() {
			return {
				selectedFile: undefined,
				currentFile: undefined,
				progress: 0,
				isError: false,
				message: "",
			};
		},
		methods: {
			selectFile(file) {
				this.selectedFile = file;
				this.isError = false;
				this.message = "";
			},
			upload() {
				this.progress = 0;

				this.currentFile = this.selectedFile

				DemonstrationsApi.upload(this.selectedFile, event => {
					this.progress = Math.round((100 * event.loaded) / event.total);
				})
					.then(response => {
                        this.$router.push({ name: 'edit', params: { complexId: response.data.complexId, fromUpload: true }})
					})
					.catch(error => {
						this.progress = 0;
						this.message = error.response.data.message;
						this.isError = true;
						this.currentFile = undefined;
					});

				this.selectedFile = undefined;
			},
		}
	};
</script>

<style>
</style>